/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";
@import url("../node_modules/font-awesome/css/font-awesome.min.css");
@import './app/components/carousel.theme.scss';
@import "~bootstrap/dist/css/bootstrap.min.css";
/* You can add global styles to this file, and also import other style files */
:root {
  --primary-bg-color: #ffffff;
  --secondary-bg-color: #eeeeee;
  --sidebar-bg-color: #f8f8f8;
  --primary-text-color: #595959;
  --secondary-text-color: #1d1d1f;
  --graph-text-color: #2c2d31;
  --gridline-color: #45424528;
  --primary-select-color: #277ef3;
  --border-color: #cccccc;
  --white-text-color: #ffff;
  --mat-expansion-panel: #ffffff;
  --tabs-text: #191919;
  --primary-topbar-color: #277ef3;
}

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  background-color: #eee;
  overflow-y: clip;
  overflow-x: hidden !important;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "sfpro-r", sans-serif;
}

@font-face {
  font-family: "sfpro-l";
  src: url(assets/fonts/SFProText-Light.ttf) format("opentype");
}

@font-face {
  font-family: "sfpro-r";
  src: url(assets/fonts/SFProText-Regular.ttf) format("opentype");
}

@font-face {
  font-family: "sfpro-m";
  src: url(assets/fonts/SFProText-Medium.ttf) format("opentype");
}

@font-face {
  font-family: "sfpro-sb";
  src: url(assets/fonts/SFProText-Semibold.ttf) format("opentype");
}

@font-face {
  font-family: "sfpro-b";
  src: url(assets/fonts/SFProText-Bold.ttf) format("opentype");
}

.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}

.padding-left-5 {
  padding-left: 5px;
}
.padding-left-0 {
  padding-left: 0;
}
.padding-left-10 {
  padding-left: 10px !important;
}
.padding-right-5 {
  padding-right: 5px;
}
.padding-right-10 {
  padding-right: 10px;
}
.padding0 {
  padding: 0;
}
.padding10 {
  padding: 10px;
}

.padding15 {
  padding: 15px;
}

.margin-top-12 {
  margin-top: 12px;
}
.wid30p {
  width: 30%;
}

.wid80p {
  width: 80%;
}
.wid100px {
  width: 100px;
}
.wid100 {
  width: 100%;
}
.firstrow {
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  align-items: center;
  border-bottom: 1px solid #dfdfe0;
  padding: 5px 0;
  margin: 0 0px;
}

.headertext {
  color: #595959;
  font-size: 12px;
  text-transform: uppercase;
}

.toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.d-fc {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 205px);
  overflow-y: auto;
  overflow-x: hidden !important;
}

.p-20 {
  padding: 0px 20px 60px 20px;
}

.firstrow1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dfdfe0;
  padding: 10px;
}

.headertext1 {
  color: #595959;
  font-size: 12px;
  text-transform: uppercase;
}

.toolbar1 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.toolbar1 app-dropdown {
  padding: 0 4px;
}

#alerts {
  border-collapse: collapse;
  width: 99.99%;
  color: #595959;
  font-size: 12px;
  margin-top: 20px;
}

#alerts td {
  border: 1px solid #eee;
  padding: 10px 10px;
}

#alerts tr:nth-child(even) {
  background-color: #f8f8f8;
}

#alerts tr:nth-child(odd) {
  background-color: #fff;
}
.border-red {
  border-color: red !important;
}
.color-red {
  color: red;
}

#alerts th {
  padding: 6px 10px;
  text-align: left;
  color: #595959;
  font-size: 12px;
  background-color: transparent;
  height: 45px;
}

#alerts .header {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background-color: #dddddd !important;
}

.comment-input {
  border: 1px solid #dfdfe0;
  border-radius: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 4px 10px;
  font-size: 12px;
  color: #2c3a43;
  width: 100%;
  outline: none;
}

.cbox {
  width: 30px;
}

.cbox input {
  height: 12px;
  vertical-align: middle;
}

/* Pagination */

.pagination-container {
  background: white;
  border-bottom-left-radius: 4px;
  padding: 10px;
  border-top: 1px solid #ddd;
  border-bottom-right-radius: 4px;
  display: flex;
  align-items: center;
}

.pagination {
  display: inline-block;
}

.pagination a {
  color: #595959;
  float: left;
  font-size: 12px;
  padding: 4px 8px;
  text-decoration: none;
  line-height: 1;
  vertical-align: middle;
}

.pagination a.active {
  background-color: #2a7cf6;
  color: white;
  border-radius: 5px;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
  border-radius: 5px;
}

input[type="text"] {
  overflow: visible;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 4px;
  margin-top: 0px;
 // width: 230px;
  outline: none;
}
input[type="password"] {
  overflow: visible;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 4px;
  outline: none;
}

input[type="text"]:focus {
  border-color: #2a7cf6;
}
input[type="number"] {
  overflow: visible;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 5px;
  outline: none;
}

input[type="number"]:focus {
  border-color: #2a7cf6;
}
input[type="time"] {
  overflow: visible;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 5px;
  outline: none;
}

input[type="time"]:focus {
  border-color: #2a7cf6;
}

.padding-right-10p {
  padding-right: 10px;
}
.margin-left-10p {
  margin-left: 10px;
}
.padding-right-20p {
  padding-right: 20px;
}
.padLeft0 {
  padding-left: 0;
}
.fsize12 {
  font-size: 12px;
}
.fsize14 {
  font-size: 14px;
}
.mar0 {
  margin: 0;
}
.btn-info,
.btn-info:hover,
.btn-info:active {
  background-color: #2a7cf6 !important;
  font-size: 12px;
}

.card-body,
.cardTitle {
  padding: 14px;
}

.mat-snack-bar-container {
  color: #fff;
  font-weight: 500;
}

.mat-snack-bar-container.severity-success {
  background-color: #4caf50;
}

.mat-snack-bar-container.severity-info {
  background-color: #2196f3;
}

.mat-snack-bar-container.severity-warning {
  background-color: #ff9800 !important;
}

.mat-snack-bar-container.severity-error {
  background-color: #f44336 !important;
}

.font-size-12p {
  font-size: 12px;
}
.font-size-14p {
  font-size: 14px;
}

/* Login and Forgot page style -start */
.siteHome {
  .mainCon {
    display: flex;
    height: 100%;
  }

  .leftCon,
  .rightCon {
    width: 50%;
  }

  .leftCon {
    background-color: #ffff;
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
      color: #0a0d1a;
      text-align: center;
      margin: 0px;

      span {
        color: #2a7cf6;
      }
    }

    img {
      display: block;
      margin: 50px auto;
      width: 609px !important;
      height: 100% !important;
    }

    p {
      color: #595959;
      text-align: center;
      margin: 0px;
      font-size: 14px;
    }
  }

  .rightCon {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #fff;

    .imgcon {
      margin-bottom: 50px;
    }

    .inputcon {
      margin-bottom: 30px;
    }
    .inputcon1 {
      margin-top: -25px;
      position: relative;
      left: 22px;
    }

    label {
      display: block;
      font-size: 12px;
      color: #595959;
      text-align: left;
    }

    input[type="text"],
    input[type="password"] {
      display: block;
      background-color: #ffffff;
      border-color: #dddddd;
      border-radius: 2px;
      color: #0a0d1a;
      outline: 0;
      padding: 11px 30px 10px 10px;
      font-size: 0.8rem;
      border-radius: 5px;
      width: 300px;
    }

    .errorTextInput,
    .errorTextPawwsord {
      font-size: 10px;
      color: red;
      text-align: left;
      position: relative;
      top: 10px;
      }
    }

    .btn {
      width: 120px;
      background: #2a7cf6;
      border: unset;
      color: #fff;
      padding: 8px 15px;
      border-radius: 5px;
    }
  }


@media screen and (max-width: 800px) {
  .siteHome .leftCon {
    display: none;
  }

  .siteHome .rightCon {
    width: 100%;
  }
}

/* Login and Forgot page style -end */

.cdk-overlay-container {
  z-index: 1061;
}

.swal2-popup {
  .swal2-header {
    font-size: 10px;
  }

  .swal2-actions {
    font-size: 12px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.modal-header .close {
  padding: 0;
  margin: 0;
}

.modal-title {
  font-size: 14px;
  color: #0a0d1a;
  font-weight: bold;
}
.adminHeader {
  margin-left: 0;
  margin-right: 0;
  padding: 18px 0;
  min-height: 65px;
  button {
    font-size: 14px;
    padding: 4px 30px;
  }
}
.adminContent {
  .card {
    height: 70vh;
    overflow: auto;
  }
}
.p10 {
  padding: 10px;
}
.pL0 {
  padding-left: 0;
}
.pL10,
.pL-10 {
  padding-left: 10px;
}
.pT-10 {
  padding-top: 10px;
}
.pT-15 {
  padding-top: 15px;
}
.pR-0,
.pR0 {
  padding-right: 0px;
}
.pB-10 {
  padding-bottom: 10px;
}
.mB-10 {
  margin-bottom: 10px;
}
table{
  margin-bottom: 15px !important;
}

.datatable {
  border-collapse: collapse;
  width: 99.99%;
  color: #595959;
  font-size: 12px;
  margin-top: 20px;
  .header {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    background-color: #dddddd !important;
    th {
      padding: 6px 20px;
      text-align: left;
      color: #595959;
      font-size: 12px;
      background-color: transparent;
      border-right: 1px solid #fff;
      text-transform: capitalize;
    }
  }
  td {
    border: 1px solid #eee;
    padding: 10px 20px;
  }
}

.nav-link {
  display: block;
  padding: 4px 15px;
  color: var(--primary-text-color);
  cursor: pointer;
  font-size: 14px;
}
.border-active {
  color: #fff !important;
  background-color: #427cf8;
  border-radius: 4px;
  border: none;
  padding: 2px 20px;
  font-size: 14px;
  cursor: pointer;
}
.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: #f8f8f8;
}
::ng-deep .assetDetail .toolbar .mat-form-field-wrapper {
  width: 100%;
}
@media screen and (max-width: 1160px) {
  .assetDetail {
    .toolbar {
      i.fa:not(.fa-refresh) {
        display: none;
      }
      .dropdown {
        width: 140px;
      }
      .dropdown .mat-form-field-wrapper {
        width: 68%;
      }
    }
    .data_frequency {
      font-size: 10px;
    }
  }
}

@media screen and (max-width: 1140px) {
  .tab-wrap {
    .tab-content {
      width: 140px !important;
    }
    label {
      font-size: 1.4vw !important;
    }
    .slide {
      width: 140px !important;
    }
  }
  .onlineText {
    display: none;
  }
}
::ng-deep .card .cardTitle {
  font-size: 15px;
  color: #0a0d1a;
  padding: 14px;
  border-bottom: 1px solid #ddd;
}

.pos-relative {
  position: relative;
}
$color-loader: #2a7cf6;
.chartoverlay {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #000;
  opacity: 0.1;
  z-index: 99;
  top: 0;
}

.chartspinner {
  -webkit-animation: frames 1s infinite linear;
  animation: frames 1s infinite linear;
  background: transparent;
  border: 5px solid #fff;
  border-radius: 100%;
  border-top-color: $color-loader;
  width: 2vw;
  height: 2vw;
  opacity: 0.6;
  padding: 0;
  position: absolute;
  z-index: 999;
  top: 46%;
  left: 46%;
}
@keyframes frames {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.error-message {
  text-align: center;
  font-size: 16px;
  color: #417cf7;
}
input,
input::-webkit-input-placeholder {
  font-size: 14px !important;
}
.input.searchBox {
  height: 32px;
  margin-right: 10px;
  width: 200px;
}
.mat-select-panel {
  min-width: 112px;
  max-width: 280px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding-top: 0;
  padding-bottom: 0;
  max-height: 256px;
  min-width: 100%;
  border-radius: 4px;
  outline: 0;
}
.mat-select-panel-wrap {
  /* flex-basis: 100%; */
  position: relative;
  //top: 260px;
  right: 10px;
  width: 200px;
}
.mat-select-value-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 11.5px;
  position: relative;
  // top: 1px;
  top: -1px !important;
}
.md-drppicker td.active, .md-drppicker td.active:hover {
  background-color: #2a7cf6 !important;
  border-color: transparent;
  color: #fff;
}
.md-drppicker .btn, .md-drppicker .btn:focus, .md-drppicker .btn:hover {
  background-color: #2a7cf6 !important;
}
// ::-webkit-scrollbar {
//   height: 5px !important;
//   width: 5px !important;
//   background: lightgray !important;
//   }
//   ::-webkit-scrollbar-track {
//   box-shadow: black !important;
//   -webkit-box-shadow: inset 0 0 6px rgb(73, 73, 78) !important;
//   border-radius: 6px !important;
//   }
//   ::-webkit-scrollbar-thumb {
//   border-radius: 5px !important;
//   box-shadow: black !important;
//   -webkit-box-shadow: inset #2a7cf6 !important;
//   background: #2a7cf6 !important;
//   }
 .customScroll{
  // display: flex;
  // flex-direction: column;
  //height: calc(100vh - 240px);
  overflow-y: auto;
  overflow-x: hidden;
  }
::ng-deep.ngx-pagination {
  padding-left: 0px !important;
 .pagination-previous,.pagination-next{
      span{
          display: none !important;
      }
  }
}

::ng-deep.ngx-pagination .current,
.btn-info {
  background: #2a7cf6 !important;
  border: transparent !important;
  border-radius: 5px !important;
  height: 26px;
  width: 28px;
  text-align: center;
}

::ng-deep.ngx-pagination .current:hover {
  background: #2a7cf6 !important;
  border-radius: 5px !important;
  border: transparent !important;
  height: 26px;
  width: 28px;
  text-align: center;
}
::ng-deep .ngx-pagination .pagination-previous.disabled::before {
  content: '\f053' !important;
  font-family: "FontAwesome" !important;
  display: inline-block;
  margin-right: 0.5rem;
  height: 16px;
  width: 9px;
}
::ng-deep .ngx-pagination .pagination-next.disabled::after {
  content: '\f054' !important;
  font-family: "FontAwesome" !important;
  display: inline-block;
  margin-left: 0.5rem;
  height: 16px;
  width: 9px;
  color: #595959;
}
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #2A7CF6;
  background-color: #f8f8f8;
}
.col-md-1-5{
  flex: 0 0 12.5%;
  max-width: 12.5%;
  padding: 0px 10px;
}
.bg-color{
  background-color: #fff;
}
::ng-deep .mat-select {
  display: inline-block;
  width: 100%;
  outline: none;
  position: relative !important;
  top: 3px !important;
}
@media (min-width: 992px){
.modal-lg, .modal-xl {
    max-width: 1000px !important;
}
}
::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
  background: #f8f8f8 !important;

}
::-webkit-scrollbar-track {
  // box-shadow: black;
  // -webkit-box-shadow: inset 0 0 6px rgb(73, 73, 78);
  border-radius: 5px;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  // box-shadow: black;
  // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background-color: #ccc !important;

}
// Through Put
.ellipssis{
 display: inline-block;
 width: 200px;
 overflow: hidden;
 text-overflow: ellipsis;
 white-space: nowrap;
}
.m_inline-1{
  margin-inline: 8px;
}
.m_inline-2{
  margin-inline: 10px;
}
.p_inline-1{
  padding-inline: 8px;
}
.p_inline-2{
  padding-inline: 10px;
}
.cp{
  cursor: pointer;
}
.f-sb{
  font-family: 'sfpro-sb';
}
.f-m{
font-family: 'sfpro-m';
}
.g-10{
  gap: 10px;
}
.fs-13{
  font-size:13px
}
.fs-14{
  font-size:14px
}
.fs-sm{
  font-size: 10px;
}
.fs-lg{
  font-size: 20px;
}
.insight_button_sty{
  background-color: #2897C6;
    color: #fff;
    font-size: 12px;
}
.action_btn_sty{
    background-color: #2897C6;
    color: #fff;
    font-size: 10px;
    border-radius: 50%;
    padding: 2px 5px;
}
.no_data_text{
  font-family: 'sfpro-m';
    font-size: 13px;
    margin-top: 10px;
}
.throughput_title{
  font-size: 14px;
  color: #2F2F2F;
}
.title_border{
width: 60px;
border: 0.1px solid #2a7cf6;
}
.throughput_graphs{
    display: grid;
    grid-template-columns: repeat(2, 49.3%);
    grid-auto-flow: dense;
    gap: 20px;
    overflow-y: auto;
    overflow-x: hidden;
}
.graph_cards{
  background-color: #fff;
  border-radius: 8px;
  height: 345px;
}
.graph_header_bar{
  display: grid;
  grid-template-columns: 50% 50%;
}
.serach_bar{
         height: 32px;
          width: 290px;
          border: 1px solid #fff;
          border-radius: 4px;
          padding: 10px;
          outline: none;
          background-image: url('./assets/images/search_icon.png');
          background-repeat: no-repeat;
    background-size: 20px;
    background-position-x: right;
    background-position-y: center;
}
.serach_bar:focus{
  background-image: none;
}
.noData{
  height: 300px;
  display:grid;
  place-items: center;
}
.table_container_throughput{
  height: 400px;
  margin: 10px;
  overflow-y: auto;
}
.table-condensed td {
  padding: 10px;
  vertical-align: top;
  border-top: 1px solid var(--secondary-bg-color);
  font-size: 15px !important;
  color: var(--primary-text-color);
}
.table-condensed thead th {
  vertical-align: bottom;
  border-top: 0px !important;
  font-family: 'sfpro-sb';
  color: #fff;
   font-size: 14px;
 background-color: #8095d5;
  border-bottom: 1px solid #cccc;
}
.d-grid{
  display: grid;
}

.depicit_head{
  grid-template-columns: repeat(5,20%);
  padding: 2px 10px;
}
